import uuid from 'uuid/v4';
import debug, { DEBUG } from '../../debug';
import { AGENT_PORT, IS_BROWSER_EDGE, IS_BROWSER_IE11, IS_DEV } from '../../env';
import eventLogger from '../../ClientEventLogger';
import {
    API_CLIENT_TO_BROWSER,
    CLIENT_WINDOW_METHODS, FILE_PROCESSING_BLOCK_STATE,
    FILE_PROCESSING_STATE, FILE_PROCESSING_TYPE
} from '../sharedConstants';
import reg from '../DOMRegistry';
import { isFileInput } from '../DOM';
import { messageToBrowser } from './browserMessageBus';
import page from './ClientPage';
import api from './ClientAPI';
import { getEventTarget } from '../../DOMUtils';

let maxFilesSize = 0;
const logger = debug.create('ClientUploadManager', DEBUG);

function getUploadInfoForLogger(uploadInfo) {
    const { id, frameId } = uploadInfo;
    const { lastModified, name, size, type } = uploadInfo.file;
    return { id, frameId, lastModified, fileName: name, size, type };
}

function sendErrorEvent(message, uploadInfo) {
    eventLogger.measureAndSend(eventLogger.EVENT_CLIENT_UPLOAD,
        { message, ...getUploadInfoForLogger(uploadInfo), success: false });
}

function sendMetricEvent(message, uploadInfo) {
    eventLogger.measureAndSend(eventLogger.EVENT_CLIENT_UPLOAD,
        { ...getUploadInfoForLogger(uploadInfo), success: true });
}

function sendFileUploadNotification(uploadInfo, state, params) {
    messageToBrowser(API_CLIENT_TO_BROWSER.fileProcessing, {
        id: uploadInfo.id,
        bunchId: uploadInfo.bunchId,
        startTime: uploadInfo.startTime,
        name: uploadInfo.file.name,
        size: uploadInfo.file.size,
        type: FILE_PROCESSING_TYPE.UPLOAD,
        state,
        ...params,
    });
}

function notifyError(uploadInfo, errorId) {
    sendFileUploadNotification(
        uploadInfo,
        FILE_PROCESSING_STATE.BLOCKED,
        { errorId: errorId || FILE_PROCESSING_BLOCK_STATE.GENERIC_ERROR },
    );
}

function prepareURLForUpload(uploadInfo) {
    const AGENT_BASE = `${window.location.protocol}//${window.location.hostname}${IS_DEV ? `:${AGENT_PORT}` : ''}`;
    const API_PATH = eventLogger.context.uploadPath || '/api/v1/dlp/upload';

    const url = new URL(AGENT_BASE + API_PATH);
    url.searchParams.set('id', uploadInfo.id);
    url.searchParams.set('bunchId', uploadInfo.bunchId);
    url.searchParams.set('frameId', uploadInfo.frameId);
    url.searchParams.set('nodeId', uploadInfo.nodeId);
    url.searchParams.set('startTime', uploadInfo.startTime);
    url.searchParams.set('name', uploadInfo.file.name);
    url.searchParams.set('pageUrl', page.location);
    url.searchParams.set('pageId', eventLogger.context.pageId);
    url.searchParams.set('size', uploadInfo.file.size);
    url.searchParams.set('mime', uploadInfo.file.type);
    url.searchParams.set('relativePath', uploadInfo.relativePath);

    return url.toString();
}

const intervals = {};
// keep track of all uploads xhrs to be able to cancel them
// for more info https://proofpoint.atlassian.net/browse/ISO-10337
export const uploadsXHR = {};

async function uploadByFileInfo(uploadInfo) {
    eventLogger.mark(eventLogger.EVENT_CLIENT_UPLOAD);
    // check for customer policy limit. any way the agent will block any file larger than 1GB
    if (maxFilesSize && uploadInfo.file.size > maxFilesSize) {
        sendErrorEvent('upload blocked in client as size exceeded', uploadInfo);
        notifyError(uploadInfo, FILE_PROCESSING_BLOCK_STATE.SIZE_EXCEEDED);
        return false;
    }

    return new Promise((resolve) => {
        const xhr = new window.XMLHttpRequest();
        const uploadedFileUrl = prepareURLForUpload(uploadInfo);

        xhr.onerror = (error) => {
            logger.error('Failed to send XHR:', { response: xhr.response, status: xhr.status, error });
            sendErrorEvent('failed to upload to agent', uploadInfo);
            resolve(false);
        };
        xhr.onload = () => {
            if (xhr.status === 200) {
                sendMetricEvent('client finished to upload to agent', {
                    ...uploadInfo,
                    timeMs: uploadInfo.startTime ? Date.now() - (new Date(uploadInfo.startTime)).getTime() : 0,
                });
                resolve(true);
            } else {
                logger.error('Error:', { response: xhr.response, status: xhr.status });
                sendErrorEvent('failed to upload to agent', uploadInfo);
                if (xhr.status === 413) {
                    notifyError(uploadInfo, FILE_PROCESSING_BLOCK_STATE.SIZE_EXCEEDED);
                } else {
                    notifyError(uploadInfo);
                }
                resolve(false);
            }
        };
        xhr.onloadend = () => {
            clearInterval(intervals[uploadInfo.id]);
        };

        // extend session every 3 minutes
        // to avoid session timeout during long uploads
        intervals[uploadInfo.id] = setInterval(() => {
            api.uploadInProgress(uploadInfo);
        }, 1000 * 60 * 3);

        xhr.open('POST', uploadedFileUrl, true);
        xhr.send(uploadInfo.file);

        api.uploadInProgress(uploadInfo);
        uploadsXHR[uploadInfo.id] = () => {
            xhr.abort();
            clearInterval(intervals[uploadInfo.id]);
            resolve(false);
        };
    });
}

let uploadPromiseChain = Promise.resolve();

function uploadFiles(uploadFileInfos, bunch) {
    // show a notification item for each upload
    uploadFileInfos.forEach((uploadFileInfo) => {
        sendFileUploadNotification(uploadFileInfo, FILE_PROCESSING_STATE.SCANNING);
    });

    // upload one-by-one
    uploadFileInfos.forEach((uploadInfo) => {
        uploadPromiseChain = uploadPromiseChain
            .then(async () => uploadByFileInfo(uploadInfo))
            .catch(() => notifyError(uploadInfo))
            .then((isUploaded) => {
                if (isUploaded) {
                    bunch.uploadedIds.push(uploadInfo.id);
                }
            });
    });

    uploadPromiseChain = uploadPromiseChain.then(() => api.processUploadedBunch(bunch));
}

function createUploadBunch(isDragDrop = false) {
    return {
        id: 'bunch_' + uuid(),
        uploadedIds: [],
        isDragDrop,
    };
}

function createUploadInfo(file, input, bunchId) {
    const date = new Date();
    return {
        id: uuid(),
        file,
        bunchId,
        startTime: date.toISOString(),
        nodeId: input && reg.getNodeId(input),
        frameId: input && reg.getFrameId(input),
        relativePath: file.webkitRelativePath || '',
    };
}

function onFileInputClick(event) {
    event.stopImmediatePropagation();

    if (event.isTrusted) {
        event.preventDefault();
        return;
    }

    const input = getEventTarget(event);
    input.value = '';
    const onChange = () => {
        reg.removeEventListener(input, 'change', onChange, false);

        if (input.files.length > 0) {
            const bunch = createUploadBunch(false);
            const fileUploadInfos = Array.from(input.files).map((file) => createUploadInfo(file, input, bunch.id));
            uploadFiles(fileUploadInfos, bunch);
        } else {
            sendErrorEvent('no file to upload', event);
        }
    };
    reg.removeAllEventListeners(input);
    reg.addEventListener(input, 'change', onChange, false);
}

async function retrieveDroppedFiles(item) {
    if (item.isFile) {
        return [item];
    }

    if (item.isDirectory) {
        return new Promise((resolve) => {
            try {
                item.createReader()
                    .readEntries((entries) => {
                        Promise.all(entries.map((entry) => retrieveDroppedFiles(entry)))
                            .then((res) => res.flat())
                            .then(resolve, () => resolve([]));
                    }, () => resolve([]));
            } catch (error) {
                resolve([]);
            }
        });
    }

    return [];
}

export function openUploadPopup(frameId, nodeId, policy) {
    maxFilesSize = policy.maxFilesSize;
    if (!policy.allowUploads) {
        eventLogger.sendEventFileUploadAttempt();
        const uploadData = createUploadInfo({});
        sendErrorEvent('upload blocked in client', uploadData);
        notifyError(uploadData, FILE_PROCESSING_BLOCK_STATE.BLOCKED_BY_POLICY);
        return;
    }

    let node = reg.getNode(frameId, nodeId);
    if (!node) {
        return;
    }

    window[CLIENT_WINDOW_METHODS.openFilePicker] = () => {
        node = reg.getNode(frameId, nodeId);
        if (node) {
            // <input type=file /> is bugged in Edge <= 44
            // after doing real of programmatic click no file dialog
            if (IS_BROWSER_EDGE) {
                node.type = 'text';
                node.type = 'file';
            }

            if (IS_BROWSER_IE11 || IS_BROWSER_EDGE) {
                // in this way, this event will be un-trusted on ie11 and edge.
                const clickEvent = document.createEvent('HTMLEvents');
                clickEvent.initEvent('click', true, true);
                node.dispatchEvent(clickEvent);
            } else {
                node.click();
            }
        }
        delete window[CLIENT_WINDOW_METHODS.openFilePicker];
    };

    messageToBrowser(API_CLIENT_TO_BROWSER.openUploadPopup);
}

export function openFilePicker(frameElement) {
    if (!frameElement || !frameElement.contentWindow) {
        sendErrorEvent('can`t open file-picker and there isn`t content window to attach');
        return;
    }

    try {
        const openFilePickerFunction = frameElement.contentWindow[CLIENT_WINDOW_METHODS.openFilePicker];
        if (openFilePickerFunction) {
            openFilePickerFunction();
        }
    } catch (e) {
        sendErrorEvent('can`t open file-picker', { error: e.message });
        debug.recordError(e);
    }
}

export function initUploadsWatcher() {
    page.onNewDocument((frame) => {
        reg.addEventListener(frame.doc, 'click', (event) => {
            const target = getEventTarget(event);
            if (isFileInput(target)) {
                onFileInputClick(event);
            }
        }, true);
        reg.addEventListener(frame.doc, 'drop', (event) => {
            event.preventDefault();

            const items = event.dataTransfer && event.dataTransfer.items;
            if (items && items.length > 0) {
                const promises = Array.from(items).map((item) => retrieveDroppedFiles(item.webkitGetAsEntry()));

                Promise.all(promises)
                    .then((res) => {
                        const fileEntries = res.flat();

                        return Promise.all(fileEntries.map((fileEntry) => new Promise((resolve, reject) => {
                            return fileEntry.file((file) => resolve([file, fileEntry]), reject);
                        })));
                    })
                    .then((files) => {
                        const bunch = createUploadBunch(true);
                        const fileUploadInfos = files.map(([file, fileEntry]) => {
                            const relativePath = fileEntry.fullPath.replace(/^\//, '');
                            const isFile = relativePath === file.name;

                            return {
                                ...createUploadInfo(file, event.target, bunch.id),
                                relativePath: isFile ? '' : relativePath,
                            };
                        });
                        uploadFiles(fileUploadInfos, bunch);
                    });
            }
        }, true);
    });
}
