/* global Blob */
import once from 'lodash/once';
import uuid from 'uuid/v4';
import throttle from 'lodash/throttle';
import EventLogger from './mirror/EventLogger';
import { messageToBrowser } from './mirror/client/browserMessageBus';
import clientConfig from './clientConfig';
import debug from './debug';
import {
    API_CLIENT_TO_BROWSER,
    FILE_PROCESSING_BLOCK_STATE,
    FILE_PROCESSING_STATE,
    FILE_PROCESSING_TYPE,
    POST_FILE_EVENT_LOGGER_TYPE,
    RELATIVE_PATH_OF_BROWSER_BUNDLE,
    RELATIVE_PATH_OF_CLIENT_BUNDLE,
} from './mirror/sharedConstants';
import { AGENT_PORT, IS_DEV } from './env';

const CATEGORIES_KEY = 'categories';
const MIN_ADDED_TIME_FOR_429 = 300;
const mediaLogger = debug.create('MediaSourceExtensions');
const IFRAME_RESOURCE_TYPE = 'iframe';

function sendExtendedXHR(apiPath, data, handler, timeout) {
    const xhr = new window.XMLHttpRequest();
    xhr.open('POST', apiPath);
    if (timeout) {
        xhr.timeout = timeout;
    }
    if (handler) {
        xhr.onload = handler;
        xhr.onerror = handler;
    }
    xhr.setRequestHeader('Content-Type', 'application/json;charset=UTF-8');
    xhr.send(data ? JSON.stringify(data) : '');
}
function sendXHR(apiPath, data) {
    sendExtendedXHR(apiPath, data, null, null);
}

export class ClientEventLogger extends EventLogger {
    EVENT_CLIENT_BUNDLE_LOAD_BASIC = 'client_bundle_load_basic';
    EVENT_CLIENT_IFRAME_LOAD_BASIC = 'client_iframe_load_basic';
    EVENT_CLIENT_IFRAME_LOAD_FULL = 'client_iframe_load_full';
    EVENT_CLIENT_SUSPECTED_AS_BLANK_PAGE = 'client_suspected_as_blank_page';
    EVENT_CLIENT_FCP_OF_NESTED_IFRAME = 'client_fcp_of_nested_iframe';
    EVENT_CLIENT_IFRAME_PAINT_DATA = 'client_iframe_paint_data';
    EVENT_CLIENT_LOAD_FULL_RAW_DATA = 'client_load_full_raw_data';
    EVENT_CLIENT_BUNDLE_LOAD_FULL = 'client_bundle_load_full';
    EVENT_CLIENT_LOAD = 'client_load';
    EVENT_CLIENT_RENDER = 'client_render';
    EVENT_BROWSER_PAGE_LOAD = 'browser_page_load';
    EVENT_BROWSER_BUNDLE_LOAD_BASIC = 'browser_bundle_load_basic';
    EVENT_BROWSER_BUNDLE_LOAD_FULL = 'browser_bundle_load_full';
    EVENT_BROWSER_HTML_LOAD_FULL = 'browser_html_load_full';
    EVENT_BROWSER_HTML_PAINT_DATA = 'browser_html_paint_data';
    EVENT_CLIENT_FEEDBACK = 'client_feedback';
    EVENT_CLIENT_UPLOAD = 'client_upload';
    EVENT_CLIENT_WS_FIRST_CONNECT = 'client_ws_first_connect';
    EVENT_CLIENT_WS_WILL_RECONNECT = 'client_ws_will_reconnect';
    EVENT_CLIENT_WS_DISCONNECT = 'client_ws_disconnect';
    EVENT_CLIENT_WS_BUFFER_OVERFLOW = 'client_ws_buffer_overflow';
    EVENT_CLIENT_WS_HTTP_MESSAGE_FAILURE = 'client_ws_http_message_failure';
    EVENT_CLIENT_WAITING_FOR_BROWSER_START = 'client_waiting_for_browser_start';
    EVENT_CLIENT_WAITING_FOR_BROWSER_END = 'client_waiting_for_browser_end';
    EVENT_CLIENT_NETWORK_INFORMATION = 'client_network_information';
    EVENT_CLIENT_CONFIG = 'client_config';
    EVENT_CLIENT_CONTEXT_MENU = 'client_context_menu';
    API_PATH = '/events';

    EVENT_WS_MESSAGE = 'ws_message';

    constructor() {
        super();
        if (window.top === window) {
            window.ClientEventLogger = this;
        }
        try {
            if (window.parent && window.parent !== window && window.parent.ClientEventLogger) {
                this.extendContext({ ...window.parent.ClientEventLogger.context });
            }
        } catch (error) {
            debug.recordError(error);
        }
    }


    sendEventsByXHR = (analyticEvent) => {
        if (!analyticEvent) {
            return;
        }
        const apiPath = this.API_PATH;
        const xhr = new window.XMLHttpRequest();
        xhr.onload = () => {
            if (xhr.status === 429) {
                this.intervalBetweenEvents += MIN_ADDED_TIME_FOR_429 + Math.round(Math.random() * 1000);
                this.slowDown = true;
                this.eventQueue.unshift(...analyticEvent);
            }
        };
        xhr.open('POST', apiPath);
        xhr.setRequestHeader('Content-Type', 'application/json;charset=UTF-8');
        xhr.send(JSON.stringify(analyticEvent));
    };

    apiCall(analyticEvent) {
        this.sendEventsByXHR(analyticEvent);
    }

    recordMediaEvent(data) {
        mediaLogger.log('event', data);
    }

    sendExitIsolationEvent = (artifact, activityLoggerEvent) => {
        const apiPath = '/report/exitIsolation';

        const data = {
            artifact,
            disposition: 'EXIT_ISOLATION',
            classification: 'USER',
            clickId: this.context.clickId,
        };

        const retPromise = [new Promise((resolve) => {
            let result;
            if (navigator.sendBeacon) {
                const blob = new Blob([JSON.stringify(data)],
                    { type: 'application/json' });
                result = navigator.sendBeacon(apiPath, blob);
                if (result) {
                    resolve();
                }
            }
            if (!result) {
                sendExtendedXHR(apiPath, data, () => { resolve(); }, 5000);
            }
        })];

        if (activityLoggerEvent) {
            retPromise.push(new Promise((resolve) => {
                const port = IS_DEV ? `:${AGENT_PORT}` : '';
                const AGENT_BASE = `${window.location.protocol}//${window.location.hostname}${port}`;
                const agentEventApiPath = `${AGENT_BASE}/api/v1/activity-logger/immediate`;
                activityLoggerEvent.web_page_id = this.context.pageId;
                activityLoggerEvent.artifact.url = artifact || activityLoggerEvent.artifact.url;
                activityLoggerEvent.artifact.click_id = this.context.clickId;
                activityLoggerEvent.handled_at = new Date().toISOString();
                activityLoggerEvent.threat_protection = [];
                activityLoggerEvent.verdict = 'EXIT';
                sendExtendedXHR(agentEventApiPath, activityLoggerEvent, () => { resolve(); }, null);
            }));
        }

        return Promise.all(retPromise);
    };

    setCurrentPageCategories(categories) {
        localStorage.setItem(CATEGORIES_KEY, JSON.stringify(categories));
    }

    sendEventTSAScanBlock = (data) => {
        const categories = JSON.parse(localStorage.getItem(CATEGORIES_KEY) || null);
        return sendXHR('/report/tsaScanBlock', { ...data, categories });
    };
    sendEventTSAScanError = (data) => sendXHR('/report/tsaScanError', data);

    sendEventSubFrameReputation = (data) => sendXHR('/report/reputationSubFrameScan', data);

    postFileProcessingEvent = (event) => {
        if (!event.url) {
            return;
        }
        const dto = {
            eventName: null,
            isDownload: event.type === FILE_PROCESSING_TYPE.DOWNLOAD,
            clickId: event.clickId,
            url: event.url,
            categories: [],
            name: event.name || '',
            size: event.size || '',
            mime: event.mime || ''
        };
        if (event.state === FILE_PROCESSING_STATE.BLOCKED) {
            if (event.errorId === FILE_PROCESSING_BLOCK_STATE.BLOCKED_BY_POLICY
                || event.errorId === FILE_PROCESSING_BLOCK_STATE.SIZE_EXCEEDED) {
                dto.eventName = POST_FILE_EVENT_LOGGER_TYPE.DISABLED;
            }
            if (event.errorId === FILE_PROCESSING_BLOCK_STATE.BLOCKED_BY_DLP) {
                dto.eventName = POST_FILE_EVENT_LOGGER_TYPE.BLOCKED_BY_DLP;
            }
            if (event.errorId === FILE_PROCESSING_BLOCK_STATE.MALWARE) {
                dto.eventName = POST_FILE_EVENT_LOGGER_TYPE.BLOCKED_BY_MALWARE;
            }
            if (event.errorId === FILE_PROCESSING_BLOCK_STATE.NOT_SECURED) {
                dto.eventName = POST_FILE_EVENT_LOGGER_TYPE.CONTENT_BLOCK;
            }
        } else if (event.state === FILE_PROCESSING_STATE.ALLOWED) {
            dto.eventName = POST_FILE_EVENT_LOGGER_TYPE.ALLOW;
            dto.url = dto.isDownload ? event.fileUrl : dto.url;
        }

        if (dto.eventName) {
            dto.categories = JSON.parse(localStorage.getItem(CATEGORIES_KEY) || null);
            sendXHR('/report/fileDownloadUploadEvent', dto);
        }
    };

    sendEventFileUploadAttempt = throttle(() => {
        sendXHR('/report/fileUploadAttempt');
    }, 500, { leading: false, trailing: true });

    sendRuleEngineBlockEvent = (details, clickId) => {
        sendXHR('/report/ruleEngineBlock', {
            url: details.url,
            isBlocked: details.isBlocked,
            parentPageUrl: details.parentPageUrl,
            clickId: clickId || ''
        });
    };

    wsAggregate = {
        minTimeBetweenMessages: 0,
        maxTimeBetweenMessages: 0,
        sumTimeBetweenMessages: 0,
        messagesCount: 0,
    };

    markWSMessage = () => {
        const currentTiming = this.measure(this.EVENT_WS_MESSAGE);
        this.mark(this.EVENT_WS_MESSAGE);
        if (this.wsAggregate.messagesCount === 1) {
            this.wsAggregate.minTimeBetweenMessages = currentTiming;
            this.wsAggregate.maxTimeBetweenMessages = currentTiming;
        } else {
            this.wsAggregate.minTimeBetweenMessages = Math.min(currentTiming, this.wsAggregate.minTimeBetweenMessages);
            this.wsAggregate.maxTimeBetweenMessages = Math.max(currentTiming, this.wsAggregate.maxTimeBetweenMessages);
        }

        this.wsAggregate.sumTimeBetweenMessages += currentTiming;
        this.wsAggregate.messagesCount += 1;
    };

    logOnUnloadOrDisconnect = once((additional) => {
        const { wsAggregate } = this;
        wsAggregate.avgTimeBetweenMessages = wsAggregate.sumTimeBetweenMessages / (wsAggregate.messagesCount - 1);
        delete wsAggregate.sumTimeBetweenMessages;
        const data = {
            ...this.context,
            wsAggregate,
            additional,
            timeMs: this.measure(this.EVENT_WS_MESSAGE),
        };
        if (window.parent !== window) {
            messageToBrowser(API_CLIENT_TO_BROWSER.logOnIframeUnload, data);
        }
    });

    logIframeLoadingPerformance = () => {
        if (window.performance && window.performance.getEntriesByType) {
            const indexLoad = this.getNavigationPerformance();
            if (indexLoad) {
                this.sendEvent(this.EVENT_CLIENT_IFRAME_LOAD_FULL, indexLoad);
                this.logClientPageLoadingRawData(IFRAME_RESOURCE_TYPE);
            }
            const clientBundleResource = this.getResourcesLoadingPerformanceByResourcesNameSuffix(
                `/${RELATIVE_PATH_OF_CLIENT_BUNDLE}`);
            if (clientBundleResource) {
                this.sendEvent(this.EVENT_CLIENT_BUNDLE_LOAD_FULL, clientBundleResource);
            }
            this.logPaintData(true);
        }
    };

    logBrowserPageLoadingPerformance = (additional = {}) => {
        if (window.performance && window.performance.getEntriesByType) {
            const browserPageLoad = this.getNavigationPerformance();
            if (browserPageLoad) {
                this.sendEvent(this.EVENT_BROWSER_HTML_LOAD_FULL, { ...browserPageLoad, ...additional });
            }
            const browserBundleLoad = this.getResourcesLoadingPerformanceByResourcesNameSuffix(
                `/${RELATIVE_PATH_OF_BROWSER_BUNDLE}`);
            if (browserBundleLoad) {
                this.sendEvent(this.EVENT_BROWSER_BUNDLE_LOAD_FULL, { ...browserBundleLoad, ...additional });
            }
            this.logPaintData(false);
        }
    };

    logClientPageLoadingRawData(resourceType) {
        const browserPageLoad = this.getNavigationPerformance();
        if (browserPageLoad) {
            this.sendEvent(this.EVENT_CLIENT_LOAD_FULL_RAW_DATA, { ...this.getNavigationPerformanceRawData(),
                resourceType });
        }
    }

    sendStartWaitingEvent = (startTimeForEvent) => {
        const eventId = uuid();
        const waitForBrowserEventData = {
            id: eventId,
            startWaitingTime: startTimeForEvent,
            numOfRefreshes: 0,
            lastTimePing: startTimeForEvent,
        };
        localStorage.setItem('waitForBrowserEvent', JSON.stringify(waitForBrowserEventData));
        const waitingForBrowserEventData = {
            id: eventId,
            startTimeMs: Math.round(startTimeForEvent),
        };
        this.sendEvent(this.EVENT_CLIENT_WAITING_FOR_BROWSER_START, waitingForBrowserEventData);
    };

    sendEndWaitingEvent = (isSuccessful, endTime, refreshes) => {
        const waitingForBrowserDataJSON = localStorage.getItem('waitForBrowserEvent');
        localStorage.removeItem('waitForBrowserEvent');
        if (waitingForBrowserDataJSON) {
            const waitingForBrowserData = JSON.parse(waitingForBrowserDataJSON);
            const startTime = parseInt(waitingForBrowserData.startWaitingTime, 10);
            const waitingForBrowserEventData = {
                id: waitingForBrowserData.id,
                success: isSuccessful,
                numOfRefreshes: refreshes,
                timeMs: Math.round(endTime - startTime),
            };
            this.sendEvent(this.EVENT_CLIENT_WAITING_FOR_BROWSER_END, waitingForBrowserEventData);
        }
    };

    getPaintData(key, iframe) {
        if (window.performance && window.performance.getEntriesByType) {
            const paintsData = iframe ? window.getIframePerformanceData('paint') :
                window.performance.getEntriesByType('paint');
            if (paintsData) {
                const paint = paintsData.find((p) => p.name === key);
                if (paint && paint.startTime) {
                    return paint.startTime;
                }
            }
        }
    }

    /**
     * Getting the time of first-paint and first-contentful-paint for browser html or iframe
     * @param isIframe boolean - detect if the measuring is for iframe or browser
     */
    logPaintData(isIframe) {
        try {
            const firstPaint = this.getPaintData('first-paint', isIframe);
            const firstContentfulPaint = this.getPaintData('first-contentful-paint', isIframe);
            if (firstPaint && firstContentfulPaint) {
                const event = isIframe ? this.EVENT_CLIENT_IFRAME_PAINT_DATA : this.EVENT_BROWSER_HTML_PAINT_DATA;
                this.sendEvent(event, { firstPaint, firstContentfulPaint });
            } else {
                setTimeout(() => this.logPaintData(isIframe), 1000);
            }
        } catch (e) {
            debug.recordError(e, { context: `failed to send ${isIframe ? 'iframe' : 'browser'} paint event` });
        }
    }

    logClientConfigEvent() {
        const config = new (clientConfig.ClientConfig)();
        const data = {
            language: config.language,
            platform: config.platform,
            vendor: config.vendor,
            appVersion: config.appVersion,
            hasMediaSource: config.hasMediaSource,
            hasCssSupportsApi: config.css.supportsApi,
            srcset: !!config.srcset,
            webpSupport: config.hasWebp,
            innerWidth: config.screen.width,
            innerHeight: config.screen.height,
            screenWidth: config.screen.screenWidth,
            screenHeight: config.screen.screenHeight,
            deviceScaleFactor: config.screen.deviceScaleFactor,
            isMobile: config.screen.mobile,
            researchMode: !!config.researchMode,
            controlledByWebdriver: !!navigator?.webdriver,
            adBlock: !!config.ads
        };

        if (config.researchMode) {
            data.researchUserAgent = config.userAgent;
            data.researchLocation = config.location;
        }
        this.sendEvent(this.EVENT_CLIENT_CONFIG, data);
    }

    recordContextMenu = (data) => {
        this.sendEvent(this.EVENT_CLIENT_CONTEXT_MENU, data);
    }
}

export default new ClientEventLogger();
