import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Key } from '../mirror/userInput';

export default class Dropdown extends Component {
    static propTypes = {
        onClose: PropTypes.func,
    };

    static defaultProps = {
        onClose: () => {},
    };

    container = null;
    onRef = (el) => {
        this.container = el;
    };

    componentDidMount() {
        document.addEventListener('click', this.onDocumentClick);
        document.addEventListener('keydown', this.onDocumentKeypress);
        window.addEventListener('blur', this.onWindowBlur);
    }

    componentWillUnmount() {
        document.removeEventListener('click', this.onDocumentClick);
        document.removeEventListener('keydown', this.onDocumentKeypress);
        window.removeEventListener('blur', this.onWindowBlur);
    }

    onDocumentKeypress = (e) => {
        if ([Key.SPACE, Key.ENTER, Key.TAB, Key.ESC].indexOf(e.keyCode) === -1) {
            return true;
        }
        if (this.container && !this.container.contains(e.target)) {
            this.props.onClose();
        }
    };

    onDocumentClick = ({ target }) => {
        if (this.container && !this.container.contains(target)) {
            this.props.onClose();
        }
    };

    onWindowBlur = () => {
        this.props.onClose();
    };

    render() {
        return <div ref={this.onRef} {...this.props} />;
    }
}
