import EventEmitter from 'events';
import debug from './debug';
import { AGENT_PORT, IS_DEV } from './env';
import { setAdminTimeoutData } from './stores/AdminTimeoutStore';

let csrfHeaderName = null;
let csrfToken = null;
let personaId = null;
let isPlatformLoginUsed = false;

export function configureCSRF(header, token) {
    csrfHeaderName = header;
    csrfToken = token;
}

export function setPlatformXData(pId) {
    personaId = pId; // can be null if platform logged-in user is not impersonating
    isPlatformLoginUsed = true;
}

export const apiEventEmitter = new EventEmitter();

export const repeatable = (apiCallFunc) => {
    const repeatableApiCall = (body, retryCount = 0) => apiCallFunc(body).catch((res) => {
        if (res && res.status >= 500) {
            retryCount += 1;

            if (retryCount > 6) {
                throw res;
            }

            return new Promise((resolve) => {
                setTimeout(() => {
                    resolve();
                }, Math.pow(2, retryCount) * 5 * 1000);
            }).then(() => {
                return repeatableApiCall(body, retryCount);
            });
        }

        throw res;
    });

    return repeatableApiCall;
};

export const apiCall = (url, ops) => (data) => new Promise((resolve, reject) => {
    const options = { isUserAction: true, ...ops };
    apiEventEmitter.emit('adminTimeoutReset', { url, ...options });

    const xhr = new window.XMLHttpRequest();
    xhr.onerror = (e) => {
        debug.warn('API post error', url, e);
        reject(e);
    };
    xhr.onload = () => {
        if (xhr.status === 403) {
            debug.log('API returned 403', url, xhr.status, xhr.response);
            if (isPlatformLoginUsed) {
                // specific API is forbidden
                const response = new Error('Forbidden');
                response.status = xhr.status;
                reject(response);
            } else {
                window.location.reload();
            }
            return;
        } else if (isPlatformLoginUsed && xhr.status === 401) {
            debug.log('API returned 401', url, xhr.status, xhr.response);

            // unauthorized, e.g. token expired
            const personasId = new URLSearchParams(window.location.search).get('persona-id') || null;
            const personasIdUrlParamStr = personasId ?
                `?persona-id=${personasId}` : '';
            window.location.href = '/console/logout' + personasIdUrlParamStr;
        }

        let response;
        try {
            response = xhr.response ? JSON.parse(xhr.response) : null;
        } catch (e) {
            debug.warn('API', url, xhr.status, xhr.response);
            e.status = xhr.status;
            reject(e);
            return;
        }

        debug.log('API', url, xhr.status, response);

        const status = (response && typeof response.status === 'number')
            ? (response.status || xhr.status)
            : xhr.status;

        if (status !== 200) {
            if (status >= 500) {
                apiEventEmitter.emit('requestError', { url, ...options });
            }

            if (!response) {
                response = new Error('no response');
            }

            response.status = status;

            reject(response);
        } else {
            if (isPlatformLoginUsed) {
                const platformAccessTokenExpiration = xhr.getResponseHeader('X-Platform-Access-Token-Expiration');
                if (platformAccessTokenExpiration) {
                    setAdminTimeoutData(platformAccessTokenExpiration);
                    apiEventEmitter.emit('adminTimeoutReset', { url, ...options });
                }
            }
            resolve(response);
        }
    };
    xhr.open(options?.method || 'POST', url, true);
    xhr.setRequestHeader('Accept', 'application/json');
    xhr.setRequestHeader('Content-Type', 'application/json;charset=UTF-8');
    if (IS_DEV) {
        xhr.setRequestHeader('X-Proofpoint-Tracetoken', '123');
    }
    if (personaId) {
        xhr.setRequestHeader('x-persona-id', personaId);
    }
    if (csrfHeaderName && csrfToken) {
        xhr.setRequestHeader(csrfHeaderName, csrfToken);
    } else if (!IS_DEV) {
        debug.warn('csrf missing');
    }
    xhr.send(data && JSON.stringify(data));
});

export const fileApiCall = (url) => (data) => {
    return new Promise(async (resolve, reject) => {
        try {
            const headers = {
                Accept: 'application/json',
                'Content-Type': 'application/json;charset=UTF-8',
            };
            if (csrfHeaderName && csrfToken) {
                headers[csrfHeaderName] = csrfToken;
            } else {
                debug.warn('csrf missing');
            }
            if (personaId) {
                headers['x-persona-id'] = personaId;
            }
            const res = await window.fetch(url, {
                method: 'POST',
                headers,
                body: data && JSON.stringify(data),
            });
            if (!res.ok) {
                debug.log('API', url, res.status, res);
                throw new Error(`Network response was not ok. ${res.status}`);
            }
            const blob = await res.blob();
            resolve(blob);
        } catch (e) {
            reject(e);
        }
    });
};

export const AGENT_BASE = `${window.location.protocol}//${window.location.hostname}${IS_DEV ? `:${AGENT_PORT}` : ''}`;

debug.exposeGlobal({ apiCall });
